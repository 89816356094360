@font-face {
  font-family: Gilroy;
  src: url("../public/fonts/Gilroy-Bold.ttf"),
    url("../public/fonts/Gilroy-ExtraBold.otf"),
    url("../public/fonts/gilroy-extrabolditalic.otf"),
    url("../public/fonts/gilroy-light.otf"),
    url("../public/fonts/gilroy-lightitalic.otf"),
    url("../public/fonts/Gilroy-Medium.otf"),
    url("../public/fonts/gilroy-mediumitalic.otf"),
    url("../public/fonts/Gilroy.otf");
}
* {
  font-family: Gilroy;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  margin: 0;
  background-color: #0c6aa9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-direction: column;
}
.app {
  width: 100vw;
  background: url("./img/FONDO\ JUEGO.png");
  background-size: cover;
}
.boton-to-excel{
  display: block;
  margin-top: 15px !important;
  margin: auto;
  width: 20%;
  padding: auto 15px !important;
}
.arrow-l {
  width: 60px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px) !important;
  left: 66%;
  
  z-index: 9999;
}
#spin {
  transform-origin: center;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 50px);
  display: block;
  margin: 25px auto;
  z-index: 99999;
  background-color: #0c6aa9;
  text-transform: uppercase;

  font-weight: bold;
  font-size: 20px;

  width: 100px;
  height: 100px;
  font-family: sans-serif;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  letter-spacing: 1px;
  overflow: hidden;
}
.rota {
  width: 100%;
  
  line-height: 80%;
  transform-origin: top;
  transform: rotate(-90deg);
  position: relative;
  display: flex;
  justify-content: center;
  top: 35%;
  left: -5%;
  /* font-size: 1vw; */
  text-align: center;
}
.nav-logo{
  border-radius: none !important;
  width: 15%;
  height: 40%;
  margin-right: 3%;
  position: relative;
  right: 0;
}
/* style ruleta dos */
#ruleta {
  margin: 1% auto;
  width: calc(40vw);
  height: 40vw;
  background-color: #ffffff;

  position: relative;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  transition: 5s ease-out;
  border: 3px solid #fff;
}

#ruleta div {
  display: flex;
  justify-content: center;

  height: 50%;
  background-color: #0c6aa9;
  padding: 30px 0 0 0;
  font-size: 1.2vw;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  color: #fff;
  position: absolute;
  /* clip-path: polygon(100% 0, 50% 100%, 0 0); */
  /* clip-path: polygon(0 0, 100% 0, 70% 100%, 30% 100%); */

  overflow: hidden;
  transform-origin: bottom;
}
h1 {
  color: white;
  font-size: calc(18px + 1vw);
  text-align: center;
}
.btn-probar-movil{
  display: none;
}
.title-reward {
  font-size: calc(3vw ) !important;
  transform: rotate(-10deg);
  padding: 15px;
  width: 30%;
  position: relative;
  top: 10%;
  left: -3%;
  background-color: #0c6aa9;
  clip-path: polygon(17% 0, 89% 0, 81% 100%, 7% 100%);
  box-shadow: 0px 20px 0px 0px rgba(0, 0, 0, 0.9);
  animation: title-animates 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.circle-reward{
  width: 70vh;
  height: 60vh;
  background-color: #fff;
  border: 25px solid #0c6aa9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: title-animates 3s cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.subttitle-reward {
  text-transform: uppercase;
  font-size: calc(3vw ) !important;
  transform: rotate(-10deg);
  margin-top: -4%;
  width: 50%;
  height: 10%;
  padding: 0;
  background-color: rgb(0, 0, 0);
  color: #fff;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
  clip-path: polygon(17% 0, 89% 0, 81% 100%, 7% 100%);
  animation: title-animates 1s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ligth-box-reward {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: #0c6aa96c;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  top: 0;
}
.ligth-box-enter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: url(../src/img/TORTA\ FONDO\ COVER.png), #0073d2;
  background-repeat: no-repeat;
  background-size: cover;
}
.ruleta-entrada {
  width: 150vh;
  height: 150vh;
  position: relative;
  top: 10%;
  left: -30%;
  overflow: hidden !important;
}
.ruleta-entrada img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ruleta-entrada h1{
  position: absolute;
  z-index: 999;
  top: 45%;
  width: 10%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 45%;
  text-shadow: 2px 4px 5px #000000;
  cursor: pointer;
}
.ruleta-entrada img:nth-child(1) {
  z-index: 99;
}
.ruleta-entrada img:nth-child(2) {
  animation: girar-torta 5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s 1;
  transition: ease;
}
.box-left {
  height: 100vh;
  overflow: hidden;
  
  animation: width-left 3s ease 0s 1;
  width: 60%;
}
.box-right {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: width-right 3s ease 0s 1;
  width: 40%;
}
.titte-entrada {
  width: 100%;
  font-size: 62px !important;
  color: rgb(206, 236, 250);
  position: relative;
  text-align: start;
}
.logos-right {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
/* style formulario */
.formulario {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  width: 50vw;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
}
.formulario form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
}
.formulario form input {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
}
/* style table */
table {
  width: 100%;
}
table tr {
  height: 40px;
}
table td {
  text-align: center;
}
button {
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: rgb(0, 141, 141);
  color: #fff;
  font-weight: 900;
}
img {
  width: 200px;
  height: 200px;
}
.logos {
  width: 100vw;
  height: 100px !important;
  padding: 0 25px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}
.logo-bajo {
  width: 150px;
  height: 90px;
}
.logo-bajo-corteva {
  width: 110px;
  height: 90px;
}
.LogoS {
  width: 100px;
  height: 50px;
}
.LogoC {
  margin-top: 10px;
  width: 100px;
  height: 25px;
}
.marco {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 999;
}
.button{
  cursor: pointer !important;
}
.dashboard-ruletas{
  width: 70%;
  height: 500px;
  margin: auto;
  position: relative;
  top: 15%;
  display: flex;
}
.items-dashboard {
  width: 30% !important;
  height: 30% !important;

  display: flex;
  flex-direction: column;
  
  align-items: center;
 
}
.logo-ruleta{
  width: 20vw !important;
  height: 20vw;
}
.button-return{
  margin-top: 20px;
  width: 50%;
}
.color-0 {
  background-color: #0877be !important;
}
.color-1 {
  background-color: #faa41b !important;
}
.color-2 {
  background-color: #f2526a !important;
}
.color-3 {
  background-color: #4f4989 !important;
}
.color-4 {
  background-color: #08be26 !important;
}
.color-5 {
  background-color: #faa41b !important;
}
.color-6 {
  background-color: #f2526a !important;
}
.color-7 {
  background-color: #4f4989 !important;
}
.color-8 {
  background-color: #f800f8 !important;
}
.color-9 {
  background-color: #faa41b !important;
}
.color-10 {
  background-color: #f2526a !important;
}
.color-11 {
  background-color: #4f4989 !important;
}
.color-12 {
  background-color: rgb(187, 190, 14) !important;
}
.color-13 {
  background-color: rgb(108, 190, 14) !important;
}
.disabled{
  background-color: gray !important;
}
@keyframes width-left {
  to {
    width: 60% !important;
  }
  from {
    width: 0;
  }
}
@keyframes width-right {
  to {
    width: 40%;
  }
  from {
    width: 100vw;
  }
}
@keyframes girar-torta {
  to {
    transform: rotate(360deg);
  }
  from {
    transform: rotate(0deg);
  }
}
@keyframes title-animates {
  to {
    background-color: #4f4989;
    transform: rotate(-10deg) scale(1);
  }
  from {
    background-color: #1b4ffa;
    transform: rotate(-10deg) scale(0);
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 16px;
  }
  #ruleta {
    width: 80vh;
    height: 80vh;
  }
  #box {
    width: 90vw;
    height: 90vw;
  }
  #box div {
    font-size: 14px;
  }
  #spin {
    font-size: 14px;
  }
  .formulario {
    width: 90vw;
  }
  img {
    width: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 424px) {
  h1 {
    font-size: 28px;
  }
  .arrow {
    top: 25% !important;
    width: 50px;
  }
  .Ruler{
    margin-top: 50% !important;
  }
  .nav-logo{
    width: 80%;
  }
  #ruleta {
   
    width: 90vw;
    height: 90vw;
  }
  .box-left{
    display: none;
  }
  .box-right{
    width: 100%;
    animation: none;
    padding: 10px;
    font-size: 3rem;
  }
  .box-right div h1{
    font-size: 3rem !important;
  }
  .box-right div h1 span{
    font-size: 3.4rem !important;
  }
  label{
   font-size: 14px; 
  }
  .ligth-box-enter div input{
    width: 90%;
  }
  .ligth-box-enter div select{
    width: 90%;
  }
  
  button{
    font-size: 1.5rem !important;
  }
  label{
    width: 85% !important;
  }
  .rota {
    font-size: .7rem !important;
  }
  #spin {
    top: calc(50% - 50px);
    left: 0;
    right: 0;
    width: 60px;
    height: 60px;
  }
  .arrow-l {
    width: 40px;
    height: 30px;
    position: absolute;
    top: calc(50% - 10px) !important;
    left: 78%;
    
    z-index: 9999;
  }
  .title-reward{
    top: 5%;
    width: 90%;
  }
  .subttitle-reward{
    width: 90%;
  }
  .circle-reward{
    border: 5px solid #0073d2;
    width: 90%;
    height: 50%;
  }
  .circle-reward img{
    width: 100%;
    height: 100%;
  }
  .dashboard-ruletas{
    width: 70%;
    height: 500px;
    margin: auto;
    position: relative;
    top: 15%;
    display: flex;
    flex-direction: column;
  }
  .items-dashboard {
    width: 100% !important;
    height: 100% !important;
  
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
   
  }
  .logo-ruleta{
    width: 20vw !important;
    height: 20vw;
  }
  .btn-probar-movil{
    width: 100px;
    height: 100px;
    border-radius: 200px;
    position: absolute;
    top: 25px;
    right: 50px;
    background-image: url(./img/BOTON\ \(1\).png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
  }
  .nav-logo{
    border-radius: none !important;
    width: 40%;

    margin-right: 3%;
    position: relative;
    right: 0;
  }
  .probar{
    width: 40% !important;
  }
}
@media (orientation: landscape) and (max-width: 845px)  {
  #ruleta {
    width: 92vh;
    height: 92vh;
  }
  .ruleta-entrada{
    width: 80vw;
    height: 80vw;
    top: 10%;
    left: -45%;
  }
  .arrow-l {
    width: 40px;
    height: 30px;
    position: absolute;
    top: calc(50% - 25px) !important;
    left: 66%;
    
    z-index: 9999;
  }
  #spin {
    top: calc(50% - 50px);
    left: 0;
    right: 0;
    width: 60px;
    height: 60px;
  }
  .circle-reward{
    border: 5px solid #0073d2;
  }
  .rota {
    font-size: .6rem !important;
    line-height: 50%;
  }
  .ligth-box-enter div h1{
    font-size: 5vw !important;
  }
  .ligth-box-enter div h1 span{
    font-size: 6vw !important;
  }
  .ligth-box-enter div input{
    width: 90%;
  }
  .ligth-box-enter div select{
    width: 90%;
  }
  .button-form{
    height: 30px !important;
  }
  
  .logo-bajo{
    width: 100px;
    height: 60px;
  }
  .box-boton button{
    height: 30px !important;
  }
  label{
    width: 90% !important;
  }
  .box-right {
    overflow: scroll;
  }
  .box-right .title-right{
    margin-top: 100px;
    font-size: 1.5rem !important;
  }
}
button, input[type="submit"]{
  cursor: pointer !important;
  
}