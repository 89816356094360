.header{
    padding: 0px 30px !important;
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    transition: 1s;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
.header ul {
    display: flex;
    gap: 25px;
    list-style: none;
    font-size: 18px;
    font-weight: 700;
} 
.hamburguer{
    width: 40px;
    height: 40px;
    background-color: #0c6aa9;
}
a{
    text-decoration: none;
}
.buttom-download{
    background-color: #0c6aa9;
    padding: 0 10px;
}