/* styles table lis ruleta */
.table-list-ruleta{
    background-color: #fff;
    width: 85%;
    margin: 5% auto;
    border-radius: 10px;
}
/* style create ruleta */
select{
    height: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
}
option{
    color: blue;
}
.buttons-table-ruletas{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.buttons-table-ruletas button{
    width: 45%;
    height: 30px;
}
button.delete{
    background-color: red;
}
button.edit{
    background-color: blue;
}
button.view{
    background-color: rgb(96, 192, 6);
}
.dropdown-products{
    cursor: pointer;
    height: 40px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    padding: 0 5px;
}
.dropdown-product-content{
    height: 20vh;
    overflow-y: scroll;
    border: 1px solid gray;
    padding: 15px;
}
.dropdown-items{
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
}
.dropdown-items:hover{
    background-color: #0c6aa986;
}
.noneHeight{
    height: 0;
}
span.alert-danger{
    margin-top: 15px;
    border-radius: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 10px;
    height: 40px;
    background-color: red;
}
.probar{
    width: 20% !important;
    position: absolute;
    margin-left: 5%;
    z-index: 10000;
}
/* update */
.dropdown-items-update{
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.buttons-update{
    margin-top: 15px;
   width: 100%;
   display: flex;
   justify-content: center; 
   align-items: center;
   gap: 20px;
}
.buttons-update button{
    height: 40px !important;
    background-color: #0c6aa9;
    border-radius: 10px;
    width: 40%;
    font-size: 1.5rem ;
}

.popup-list-product{
    position: absolute;
    width: 50% !important;
    height: 90% !important;
    background-color: #fff;
    left: 22% !important;
 
    border-radius: 10px;
    box-shadow: 4px 4px 3px 5px rgba(0, 0, 0, 0.2);
}
.box-rewards{
    width: 90%;
    height: 75%;
    margin: auto;
    overflow: scroll;
   
}
.hover-box:hover{
    background-color: #0c6aa9;
    color: #fff;
    cursor: pointer;
}
