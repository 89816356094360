.bar-lateral{
    width: 20%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    top: 0;
    z-index: 99;
    transition: 1s;
    overflow: hidden;
}
.noneWidth{
    width: 0;
}
.noneHeight{
    height: 0 !important;
    display: none;
}
.user-information{
    display: flex;
    gap: 15px;
    margin: 10px;
    align-items: center;
}
.logo{
    width: 50px;
    height: 50px;
    border-radius: 5px;
}
.list-bar{
    margin: 25px 10px;
}
.list-bar ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.list-bar .list .items{
    cursor: pointer;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    font-weight: 700;
    background-color: #0c6aa9;
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.list-bar ul li{
    padding: 5px;
}
.list-bar ul li:hover{
    background-color: rgb(43, 168, 226);
    border-radius: 10px;
    
}

.list-bar ul li div.dropdown{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-bar ul li div.dropdown-content{
    width: 100%;
    height: auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-content ul{
    margin-top: 15px;
    width: 100%;
    list-style: none;
}
.dropdown-content ul li{
    text-align: left !important;
    color: #fff !important;
}
i{
    color: #fff;
}
a{
    color: #fff;
}