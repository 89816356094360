/* style formulario crer producto */
.Form-create-product {
  width: 50%;
  position: relative;
  display: block;
  margin: 5% auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  color: #000;
}
.Form-create-product h1 {
  color: #000;
}
.Form-create-product label {
  margin: 10px 0;
  color: #000;
  font-size: 20px;
}
.Form-create-product input {
  height: 40px;
  margin: 20px 0;
  color: #000;
  font-size: 20px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid gray;
}
.Form-create-product input[type="submit"] {
  height: 40px;
  margin: 20px 0;
  color: rgb(182, 13, 13);
  font-size: 20px;
  border: none;
  color: #fff;
  background-color: #0c6aa9;
  border-radius: 10px;
}
.Form-create-product textarea {
  height: 40px;
  margin: 20px 0;
  color: #000;
  font-size: 20px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid gray;
}
/* lista de productos */
.list-product {
  position: relative;
  margin: 25px auto;
  background-color: #fff;
  width: 85%;
  height: 65vh;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);

}
.list-product::-webkit-scrollbar {
  width: 7px;
}
.list-product::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #0c6aa9;
  border-radius: 10px;
}
table thead{
  width: 100%;
  background-color: #0c6aa9;
  color: #fff;
  position: sticky;
  z-index: 9;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  
}

table tbody tr td {
  border-bottom: 1px solid gray;
}
.colunm-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.colunm-buttons .delete {
  background-color: red;
  width: 45%;
  height: 30px;
}
.colunm-buttons .edit {
  background-color: rgb(27, 196, 182);
  width: 45%;
  height: 30px;
}
.image-product{
  width: 200px;
  height: 130px;
  border-radius: none !important;
}
input[type="file"]{
  height: 40px;
  position: relative;
}
input[type="file"]::before{
  content: 'Seleccionar imagen';
  position: absolute;
  background-color: #0c6aa9;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
}
progress{
  width: 100%;
  height: 40px;
}
.product-image{
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}