.header-list-mostradores {
  height: 40px;
  display: flex;
  padding: 0 7%;
  width: 100%;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}
.buttom-download {
  width: 30% !important;
  background-color: transparent;
}
.boton-to-excel {
  margin-top: 0 !important;
  width: 100%;
}
.input-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.input-search input {
  height: 40px;
  width: 80%;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border: 1px solid gray;
  padding-left: 15px;
}
.btn-search {
  width: 20%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  cursor: text;
}
