.popup{
    background-color: #fff;
    width: 70%;
    height: 85vh;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow-y: scroll;
}
.items-report{
    height: 40px;
    width: 100%;
    border: 0.5px solid rgba(128, 128, 128, 0.479);
}