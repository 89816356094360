.login{
    background-color: #fff;
    color: black;
    width: 50%;
    height: 40vh;
    margin: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 15px 2px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}
.login h1{
    color: #0c6aa9;
}
.login form{
    display: flex;
    flex-direction: column;
}
.login form input{
    margin: 10px;
    height: 40px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid gray;
}
.login form input[type=submit]{
    background-color: #0c6aa9;
    border-bottom: 0;
    border-radius: 10px;
    font-size: 1.5vw;
    font-weight: 700;
    color: white;
}