.light-box{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0c6aa988 !important;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.width-80{
    width: 80% !important;
}
.width-100{
    width: 100% !important;
}
.light-box .box-content{
    width: 500px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
    padding: 5%;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: center;
}
