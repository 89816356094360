.welcome{
    width: 100%;
}
.entrada-title2{
    font-size: 102px;
    color: #fff;
    position: relative;
    left: 13%;
}
.entrada-title3{
    position: relative;
    left: 23%;
}
.box-boton{
    margin-top: 5%;
    width: 100%;
    text-align: center;
}
.box-boton button{
    height: 50px;
    width: 50%;
    background-color: #faa41b;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    font-size: 2vw;
}
.button-form{
    margin-top: 3%;
    height: 50px;
    width: 50%;
    background-color: #faa41b;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    font-size: 2vw;
}
.welcome-formulario{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5vw;
    color: #fff;

}

.welcome-formulario label{
    width: 60%;
    margin-top: 2%;
}
.welcome-formulario input{
    width: 60%;
    height: 30px;
    border: none;
    border-radius: 10px;
}
.welcome-formulario select{
    width: 60%;
    height: 30px;
    border: none;
    border-radius: 10px;
    margin-bottom: -3px;
}